.icon, span.ban7, span.ban7w, span.ban10, span.ban23, span.check7, span.check23, span.exclamation7, span.exclamation23, span.trash7, span.trash10 {
  background-image: url("images/sprite.png");
}

.icon, span.ban7, span.ban7w, span.ban10, span.ban23, span.check7, span.check23, span.exclamation7, span.exclamation23, span.trash7, span.trash10 {
  background-image: url("images/sprite.png");
}

.ssi-tooltipText {
  border: 1px #b7b7b7 solid;
  border-radius: 6px;
  padding: 7px;
  color: #fff;
  display: block;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #151515;
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  opacity: 1;
  z-index: 30000;
}

.ssi-tooltipText.ssi-fade {
  transition: opacity .8s;
}

.ssi-tooltipText.ssi-fadeOut {
  opacity: 0;
}

.ssi-tooltipText.ssi-fadeIn {
  opacity: 1;
}

.ssi-button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  text-decoration: none;
  border: 1px solid #aeaeae;
  cursor: pointer;
  padding: 6px 6px;
  margin: 0 0 0 2px;
  border-radius: 3px;
  color: whitesmoke;
}

.ssi-button.error {
  background: #cf5144;
}

.ssi-button.error:hover {
  background: #ab4b3f;
}

.ssi-button.error:active {
  background: #8f493e;
}

.ssi-button.info {
  background: #006cbc;
}

.ssi-button.info:hover {
  background: #0054a0;
}

.ssi-button.info:active {
  background: #004d8e;
}

.ssi-button.success {
  background: #40b056;
}

.ssi-button.success:hover {
  background: #389e48;
}

.ssi-button.success:active {
  background: #2f963b;
}

.ssi-button[disabled] {
  opacity: 0.8;
  pointer-events: none;
}

.ssi-statusLabel {
  padding: 2px 6px;
  text-align: center;
  font-size: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 2px;
}

.ssi-statusLabel.error {
  background: #cf5144;
}

.ssi-statusLabel.success {
  background: #40b056;
}

.ssi-previewBox {
  float: left;
  width: 100%;
  color: #ccc;
  padding: 10px;
}

.ssi-dropZonePreview {
  min-height: 300px;
  border: 2px dashed #ccc;
  content: 'Drag n Drop Files';
}

.ssi-removeBtn {
  margin: 5px 0 5px 0;
  padding: 0;
}

#ssi-info::after {
  content: ' ';
  display: block;
  clear: both;
}

#ssi-info #ssi-DropZoneBack {
  z-index: -1;
  float: left;
  overflow: hidden;
}

#ssi-info #ssi-fileNumber {
  float: right;
}

#ssi-info #ssi-fileNumber:hover {
  cursor: pointer;
  color: #636363;
}

.ssi-uploader::after {
  content: ' ';
  display: block;
  clear: both;
}

.ssi-uploadFiles {
  position: relative;
  float: left;
  border: 1px solid #aaaaaa;
  overflow: hidden;
  border-radius: 3px;
  width: 180px;
  min-height: 32px;
  margin: 2px 2px 2px 0;
  font-size: 15px;
  vertical-align: middle;
  line-height: 30px;
  transition: height .3s;
  background: #FFFFFF;
  padding-right: 1px;
}

span.ban7 {
  background-position: 0px -18px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
}

span.ban7w {
  background-position: 0px -36px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
  background-color: #cf5144;
  margin-top: 2px;
  height: 17px;
}

span.ban10 {
  background-position: 0px 0px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
}

span.ban23 {
  background-position: 0px -126px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 31px;
  height: 31px;
}

span.check7 {
  background-position: 0px -54px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
  background-color: #40b056;
  margin-top: 2px;
  height: 17px;
}

span.check23 {
  background-position: 0px -157px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 31px;
  height: 31px;
}

span.exclamation7 {
  background-position: 0px -72px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
  margin-top: 2px;
  background-color: #cf5144;
  height: 17px;
  border-radius: 3px;
}

span.exclamation23 {
  background-position: 0px -188px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 31px;
  height: 31px;
}

span.trash7 {
  background-position: 0px -108px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
}

span.trash10 {
  background-position: 0px -90px;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
  width: 18px;
  height: 18px;
}

.ssi-previewBox.ssi-dragOver, .ssi-dragOver .ssi-dropZone {
  color: #ba2919;
  border-color: #ba2919;
}

.ssi-buttonWrapper {
  float: left;
  padding: 5px;
}

.ssi-noPreviewMessage {
  position: relative;
  z-index: 1;
  border-radius: 4px;
  margin: 1px;
  float: right;
  width: 35px;
  height: 31px;
  padding: 0;
}

.ssi-noPreviewMessage span {
  margin: 0 auto;
}

.ssi-noPreviewSubMessage {
  width: 15px;
  height: 15px;
  padding: 0;
}

.ssi-totalvalue {
  float: right;
  margin: 2px;
}

.ssi-upImgTd {
  position: relative;
}

.ssi-upImgTd .fa-spin {
  display: inline-block;
  position: absolute;
  top: 45%;
  left: 45%;
}

.ssi-uploadProgressNoPreview {
  position: absolute;
  display: block;
  text-align: center;
  width: 0;
  height: 35px;
  background: #5cb85c;
  transition: width .3s;
  opacity: 0.6;
}

.ssi-uploadNoDropZone {
  border: 1px solid #ccc;
}

.ssi-uploadProgress {
  margin-top: 4px;
  display: block;
  text-align: center;
  width: 0;
  height: 10px;
  background: #5cb85c;
  transition: width .3s;
}

.ssi-uploadProgressNoPre {
  position: absolute;
  height: 19px;
  margin-top: 0;
  opacity: 0.6;
}

.ssi-uploaderNP {
  position: relative;
}

.ssi-uploaderNP::after {
  content: ' ';
  display: block;
  clear: both;
}

.ssi-uploadDetails {
  width: 180px;
  max-height: 0;
  top: 37px;
  background: #FFFFFF;
  position: absolute;
  transition: max-height .2s ease-out;
  border-radius: 3px;
  overflow: hidden;
  padding-right: 2px;
}

.ssi-uploadBoxWrapper {
  float: left;
}

.ssi-uploadBoxOpened {
  max-height: 200px;
  z-index: 2000;
  overflow: auto;
  border: 0.1mm solid #dcdcdc;
  transition: max-height .5s ease-out;
}

table.ssi-fileList {
  font-size: 10px;
  margin: 5px;
}

table.ssi-fileList tr td:first-child {
  border: 0.1mm solid #dcdcdc;
  /*border: 0.1mm solid black;*/
  width: 89%;
  position: relative;
}

table.ssi-fileList tr td:nth-child(2) {
  padding-left: 7px;
}

table.ssi-fileList tr {
  line-height: 18px;
}

table.ssi-fileList tr.ssi-space > td {
  border: none;
  padding-bottom: 2px;
}

.ssi-uploadProgress.hide {
  margin-top: 0;
  opacity: 0;
  transition: opacity 1.3s;
}

.ssi-canceledProgressBar {
  width: 100% !important;
  background: #d9534f;
}

.ssi-imgToUploadTable h2 {
  margin: 0;
}

.ssi-hidden {
  display: none;
}

.ssi-imgToUploadTable tr:first-child td:first-child {
  height: 126px;
}

.ssi-imgToUploadTable tr td {
  width: 140px;
}

.ssi-imgToUploadTable {
  border: 1px solid #e1e1e1;
  color: #000000;
  font-size: 15px;
  margin-right: 4px;
  box-shadow: 0 10px 6px -6px #777;
  padding: 5px;
  display: inline-block;
  width: 150px;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
      hyphens: auto;
}

.ssi-imgToUpload {
  width: 140px;
  height: 128px;
}

.ssi-btnIn {
  float: left;
}

.ssi-ieCompatibilityForm {
  display: none;
}

span.ssi-InputLabel input[type="file"] {
  display: none;
}

.ssi-abortUpload {
  padding: 0;
}

.ssi-abortUpload .ban7w {
  margin: 0;
}

.ssi-removeBtnNP {
  border: none;
  color: #ff696d;
  font-size: 11px;
  margin: 0;
  padding: 0;
}

.ssi-InputLabel.disabled, .ssi-InputLabel.disabled:hover, .ssi-InputLabel.disabled:active {
  cursor: not-allowed;
  background: #5cb85c;
  opacity: .65;
}

.ssi-check {
  color: #005900;
}

.ssi-boxHover {
  cursor: pointer;
}

.ssi-upI.imgTd {
  position: relative;
}

/*@author http://codepen.io/tevko/pen/DdsnK*/
.document-item {
  display: inline-block;
  width: 69px;
  height: 74px;
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: inherit;
}

.document-item::before {
  position: absolute;
  width: 69px;
  height: 74px;
  left: 0;
  top: -7px;
  content: '';
  border: solid 2px #920035;
}

.document-item::after {
  content: attr(filetype);
  left: -4px;
  padding: 0px 2px;
  text-align: right;
  line-height: 1.3;
  position: absolute;
  background-color: #000;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 1px;
  top: 9px;
}

.document-item .fileCorner {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 0 0 13px;
  border-color: white transparent transparent #920035;
  position: absolute;
  top: -7px;
  left: 61px;
}
